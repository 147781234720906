import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './Store/store'
import {GoogleOAuthProvider} from '@react-oauth/google';
import './Styles/Main.scss'
import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const googleClientId: string | undefined = process.env.REACT_APP_GOOGLE_CLIENT_ID;

if (googleClientId !== undefined) {
    root.render(
        <GoogleOAuthProvider clientId={googleClientId}>
            <Provider store={store}>
                <App/>
            </Provider>
        </GoogleOAuthProvider>,
    );
} else {
    console.error('Google Client ID is not defined.');
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
