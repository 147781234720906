import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Container, Row, Col, Card} from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import {useDispatch, useSelector} from "react-redux";
import {saveNewsBySection} from "../../Store/sections";
import axios from 'axios';
import {Campaigns, OccasionsBySectionType} from "../../Types/locations";
import {saveOccasionsBySection, toggleMapLoadingOff, toggleMapLoadingOn} from "../../Store/locations";
import {NewsItem, Section} from "../../Types/section";
import {logout} from "../../Store/user";
import {showToast} from "../../Store/toasts";

const SectionH3: React.FC = () => {
    const SECTION_LINKED_H3_API_URL = process.env.REACT_APP_API_LAYER_URL + 'pull-linked/';
    const SECTION_GET_OCCASIONS_API_URL = process.env.REACT_APP_API_LAYER_URL + 'pull/';
    const SECTION_NEWS_API_URL = process.env.REACT_APP_API_LAYER_URL + 'news/';

    const dispatch = useDispatch();
    const {sectionId} = useParams();
    const [loading, setLoading] = useState(false);
    const [occasions, setOccasion] = useState<Campaigns | null>(null);
    const [sectionTitle, setSectionTitle] = useState(null);

    const [sectionNews, setSectionNews] = useState<NewsItem[] | null>(null);
    const [sectionEvents, setSectionEvents] = useState<NewsItem[] | null>(null);
    const [sectionPopularNews, setSectionPopularNews] = useState<NewsItem[] | null>(null);

    const sectionsList = useSelector((state: State) => state.sections.sections);
    const newsBySection = useSelector((state: State) => state.sections.newsBySection);
    const linkedOccasionsBySection = useSelector((state: State) => state.sections.occasionsBySection);
    const jwt = useSelector((state: State) => state.user.userCredentials);
    const h3 = useSelector((state: State) => state.locations.h3);
    const [toastShown, setToastShown] = useState(false);

    const getOccasions = () => {
        if (!h3) return;
        setLoading(true);
        let result;
        const queryParams = new URLSearchParams();

        queryParams.append('request_id', 'eacc2cd5-488b-49aa-8ac2-6e5dd167b924');
        queryParams.append('H3', h3);
        queryParams.append('timestamp', (new Date()).toISOString());

        setTimeout(() => {
            fetch(`${SECTION_GET_OCCASIONS_API_URL}${sectionId}?${queryParams}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch");
                    }
                    return response.json();
                })
                .then((data) => {
                    result = data;
                    setOccasion(result.campaigns[0]);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }, 1000);
        return result;
    }

    useEffect(() => {
        if (!sectionsList) return;
        const curSection = sectionsList.find(section => section.section_id === sectionId);
        setSectionTitle(curSection?.title);
    }, [sectionsList, sectionId]);

    useEffect(() => {
        if (!sectionId) return;

        if (newsBySection === null || (newsBySection && newsBySection[sectionId] === undefined)) {
            fetch(`${SECTION_NEWS_API_URL}${sectionId}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch");
                    }
                    return response.json();
                })
                .then((data) => {
                    const updatedNewsBySection = {
                        ...newsBySection,
                        [sectionId]: data,
                    };
                    dispatch(saveNewsBySection(updatedNewsBySection));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [sectionId, SECTION_NEWS_API_URL, dispatch, newsBySection])

    useEffect(() => {
        if (!sectionId || !jwt) return;

        if (linkedOccasionsBySection === null || (linkedOccasionsBySection && linkedOccasionsBySection[sectionId] === undefined)) {
            const queryParams = new URLSearchParams();
            queryParams.append('jwt', jwt);
            const fetchOptions = {
                timeout: 5 * 60 * 1000,
            };
            dispatch(toggleMapLoadingOn());
            axios.get(`${SECTION_LINKED_H3_API_URL}${sectionId}?${queryParams}`, fetchOptions)
                .then((response) => {
                    const data = response.data;
                    const occasionData = {
                        'h3': data?.h3_list,
                        'occasions': data?.occasions,
                        'linked': data?.linked,
                        'similar' : data?.similar,
                        'different' : data?.different,
                        'h3_time_pairs' : data?.h3_time_pairs
                    };

                    const updatedLinkedOccasionsBySection = {
                        ...linkedOccasionsBySection,
                        [sectionId]: occasionData,
                    };

                    dispatch(saveOccasionsBySection(updatedLinkedOccasionsBySection));
                    dispatch(toggleMapLoadingOff());
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401 ||  error.response.status === 403) {
                        if (!toastShown) {
                            dispatch(logout());
                            dispatch(showToast('Please authorise again with @steppechange.com email.'));
                            setToastShown(true);
                        }
                    }
                    dispatch(toggleMapLoadingOff());
                });
        }
    }, [sectionId, SECTION_LINKED_H3_API_URL, jwt, dispatch, linkedOccasionsBySection]);

    useEffect(() => {
        if (!sectionId || !h3) return;
        getOccasions();
    }, [sectionId, h3]);

    useEffect(() => {
        if (!sectionId) return;
        if (!(newsBySection) || (sectionId && newsBySection[sectionId] === undefined)) return;
        setSectionNews(newsBySection[sectionId]['news']);
        setSectionEvents(newsBySection[sectionId]['events']);
        if (newsBySection[sectionId]['popular'].length >= 1) {
            setSectionPopularNews(newsBySection[sectionId]['popular']);
        } else {
            setSectionPopularNews(null);
        }
    }, [newsBySection, sectionId])

    return <Container fluid className={'content-category'}>
        <Row className={'content-category__row'}>
            <Col xs={12} sm={12} md={12} lg={6} className={'h-100 occasions'}>
                <Row className="h-100 d-flex flex-wrap justify-content-between">
                    <Col xs={12} sm={12} md={12} lg={12} className="h-100 my-2">
                        <Card className={'occasions__id'}>
                            Section ID: {sectionId}
                        </Card>
                    </Col>
                    {loading ?
                        <>
                            {Array(3)
                                .fill(3)
                                .map((item, index) => (
                                    <Col xs={12} sm={12} md={12} lg={12} className="h-100 my-2" key={index}>
                                        <Card className={'occasions__skeleton'}>
                                            <div className='occasion-top'></div>
                                            <Skeleton duration={0.75} height="100%" style={{lineHeight: '2'}}
                                                      containerClassName="skeleton-container"/>
                                        </Card>
                                    </Col>
                                ))}
                        </>

                        : (occasions && occasions.occasions &&
                            occasions.occasions.map((occ, index) => (
                                <Col key={index} xs={12} sm={12} md={12} lg={12} className="h-100 my-2">
                                    <Card
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '121px',
                                            margin: '0 10px',
                                            filter: 'grayscale(25%)',
                                            background: 'var(--bs-gray-100)'
                                        }}
                                    >
                                        <p style={{
                                            position: 'absolute',
                                            left: '16px',
                                            bottom: '16px',
                                            right: '16px',
                                            marginBottom: '0px',
                                            color: '#000',
                                            fontWeight: '400',
                                            fontSize: '16px'
                                        }}>
                                            <strong>Campaign:</strong> {occasions.campaign_id}
                                            <br/>
                                            <strong>Occasion</strong> : {occ}
                                        </p>

                                        <div className='occasion-top'>Top {index + 1}</div>

                                    </Card>
                                </Col>
                            )))}
                </Row>
            </Col>
            <Col sm={12} md={12} lg={6} style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '32px',
                paddingTop: '24px',
                background: '#F8F9FB'
            }}>
                <Row>
                    <Col>
                        <div className='content-header'><p>{sectionTitle} Latest
                            News</p></div>
                    </Col>
                </Row>
                <Row className="h-100 d-flex flex-wrap justify-content-between">
                    {sectionNews && sectionNews.map((article) => (
                        <Col key={article.id} xs={12} sm={12} md={6} lg={6}
                             style={{
                                 borderTop: article.id === 1 || article.id === 2 ? '' : '1px solid rgba(139, 145, 154, 0.5)',
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                             }}
                        >
                            <div className='content-newscard__text'
                                 style={{minHeight: '100px', cursor: 'default', width: '100%'}}>
                                {article.title}
                                <div className='content-newscard__subheader'
                                     style={{
                                         color: '#8B919A',
                                         fontWeight: '400',
                                         fontSize: '14px',
                                         borderLeft: '1px solid #C1C7CD',
                                         height: '16px',
                                         paddingLeft: '8px',
                                         lineHeight: '16px',
                                         marginTop: '16px'
                                     }}>
                                    {new Date(article.created_at).getHours()} HOURS AGO
                                </div>
                            </div>
                        </Col>

                    ))}
                </Row>
            </Col>
        </Row>
        {sectionPopularNews ?
            <Row style={{marginBottom: '32px', padding: '32px 32px 24px'}}>
                {sectionPopularNews.map((popularArticle) => (
                    <Col key={popularArticle.id} xs={12} sm={12} md={12} lg={6} className='mb-4'
                         style={{paddingLeft: '0px'}}>
                        <Card style={{
                            height: "234px",
                            display: 'flex',
                            padding: '24px 32px',
                            backgroundImage: `url(${popularArticle.image})`,
                            backgroundSize: 'cover'
                        }}>

                            <div className='content-category__tag'>POPULAR NEWS</div>

                            <div className='content-category__title'>{popularArticle.title}</div>
                        </Card>
                    </Col>
                ))
                }
            </Row>
            :
            <Row style={{marginBottom: '32px', padding: '32px 32px 24px'}}>
                <Col xs={12} sm={12} md={12} lg={6} className='mb-4' style={{paddingLeft: '0px'}}>
                    <Card style={{height: "234px", display: 'flex', padding: '24px 32px'}}>
                        <div className='content-category__tag'>POPULAR NEWS</div>
                        <div className='content-category__title'>TBD</div>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className='mb-4' style={{padding: '0px'}}>
                    <Card style={{height: "234px", display: 'flex', padding: '24px 32px'}}>
                        <div className='content-category__tag'>POPULAR NEWS</div>
                        <div className='content-category__title'>TBD</div>

                    </Card>
                </Col>
            </Row>
        }
        <Row style={{marginBottom: '32px', padding: '0px 32px 24px'}}>
            {sectionEvents && sectionEvents.map((event) => (
                <Col
                    key={event.id}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="col flex justify-content-center"
                    style={{paddingLeft: '0px', paddingRight: '24px'}}
                >
                    <div className='content-newscard__text' style={{minHeight: '100px'}}>
                        {event.title}
                        <div className='content-newscard__subheader'
                             style={{
                                 color: '#8B919A',
                                 fontWeight: '400',
                                 fontSize: '14px',
                                 borderLeft: '1px solid #C1C7CD',
                                 height: '16px',
                                 paddingLeft: '8px',
                                 lineHeight: '16px',
                                 marginTop: '16px'
                             }}>
                            {event.event}
                        </div>

                    </div>
                </Col>
            ))}
        </Row>
    </Container>
}

export default SectionH3

interface NewsBySection {
    [section: string]: Section;
}

interface State {
    sections: {
        sections: Array<any> | null;
        newsBySection: NewsBySection | null;
        occasionsBySection: OccasionsBySectionType | null;
    },
    user: {
        userCredentials: string | null;
    },
    locations: {
        h3: string | null
    }
}
