import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LocationState, saveH3, saveLat, saveLng} from "../../Store/locations";
import MapGL, {Layer, Marker, NavigationControl, Source} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {Button, Card, Spinner} from "react-bootstrap";
import {cellToBoundary, latLngToCell} from "h3-js";
import {useLocation} from "react-router-dom";

const Mapbox = () => {
    const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const [linkedH3List, setLinkedH3List] = useState<Array<string> | null>(null)
    const [linkedH3ListLinked, setLinkedH3ListLinked] = useState<{ [key: string]: string[] } | null>(null)
    const [linkedH3ListSimilar, setLinkedH3ListSimilar] = useState<{ [key: string]: string[] } | null>(null)
    const [linkedH3ListDifferent, setLinkedH3ListDifferent] = useState<{ [key: string]: string[] } | null>(null)

    const [occasionsCoords, setOccasionsCoords] = useState<number[][][] | null>(null);
    const [sectionId, setSectionId] = useState<string | null>(null)
    const locationState = useSelector((state: { locations: LocationState }) => state.locations);
    const [h3Boundary, setH3Boundary] = useState<Array<[number, number]> | null>(null);

    const h3: string | undefined = locationState.h3;
    const lat: number | undefined = locationState.lat;
    const lng: number | undefined = locationState.lng;
    const [zoom, setZoom] = useState<number>(Number(localStorage.getItem('mapZoom')) || 3)
    const occasionsBySection = locationState.occasionsBySection;
    const isMapDataLoading = locationState.isMapDataLoading;
    const dispatch = useDispatch();

    const [viewState, setViewState] = useState({
        latitude: Number(localStorage.getItem('mapLatitude')) || lat || 0,
        longitude: Number(localStorage.getItem('mapLongitude')) || lng || 0,
        zoom: zoom,
    });

    const location = useLocation();

    useEffect(() => {
        const {pathname} = location;
        const parts = pathname.split('/');
        const lastPart = parts[parts.length - 1];
        setSectionId(lastPart);
    }, [location]);

    const handleVieStateChange = (e: any) => {
        setViewState({
            ...viewState,
            latitude: e.viewState.latitude,
            longitude: e.viewState.longitude,
            zoom: e.viewState.zoom
        });
        setZoom(e.viewState.zoom);
    }

    const handleMapClick = (event: any) => {
        if (zoom < 6) return;
        const {lng, lat} = event.lngLat;
        let h3New = latLngToCell(lat, lng, 7);

        handleLatChange(lat.toFixed(8));
        handleLongChange(lng.toFixed(8));
        handleH3Change(h3New);
    }

    const handleLatChange = (newLat: number | undefined) => {
        dispatch(saveLat(newLat));
    };

    const handleLongChange = (newLong: number | undefined) => {
        dispatch(saveLng(newLong));
    };

    const handleH3Change = (newH3: string) => {
        dispatch(saveH3(newH3));
    };

    useEffect(() => {
        const saveToLocalStorage = () => {
            localStorage.setItem('mapLatitude', viewState.latitude.toString());
            localStorage.setItem('mapLongitude', viewState.longitude.toString());
            localStorage.setItem('mapZoom', viewState.zoom.toString());
        };

        const intervalId = setInterval(saveToLocalStorage, 1000);

        return () => clearInterval(intervalId);
    }, [viewState]);

    useEffect(() => {
        if (!h3) return;
        const boundary = cellToBoundary(h3);
        let updatedh3Boundary: Array<[number, number]> | null = null;

        updatedh3Boundary = boundary.map((subarray) => {
            const [firstElement, secondElement] = subarray;
            return [secondElement, firstElement];
        });
        if (updatedh3Boundary) {
            updatedh3Boundary[updatedh3Boundary.length] = updatedh3Boundary[0]
        }

        setH3Boundary(updatedh3Boundary);
    }, [h3]);

    useEffect(() => {
        if (!sectionId || !occasionsBySection || !occasionsBySection[sectionId]?.h3) return;
        setLinkedH3List(occasionsBySection[sectionId]?.h3);
        setLinkedH3ListLinked(occasionsBySection[sectionId]?.linked);
        setLinkedH3ListSimilar(occasionsBySection[sectionId]?.similar);
        setLinkedH3ListDifferent(occasionsBySection[sectionId]?.different);
    }, [occasionsBySection, sectionId]);

    useEffect(() => {
        if (!linkedH3ListLinked) return;
        console.log(Object.keys(linkedH3ListLinked));
    }, [linkedH3ListLinked, linkedH3ListSimilar, linkedH3ListDifferent]);

    useEffect(() => {
        if (!linkedH3List || linkedH3List.length < 1) return;

        const updatedOccasionsCors = linkedH3List.map((el) => {
            let elToCords = cellToBoundary(el);

            let updatedEl = elToCords.map((subarray) => {
                const [firstElement, secondElement] = subarray;
                return [secondElement, firstElement];
            });
            if (updatedEl) {
                updatedEl[updatedEl.length] = updatedEl[0]
            }

            return updatedEl;
        });

        setOccasionsCoords(updatedOccasionsCors);
    }, [linkedH3List]);

    const setNewH3 = (e: string) => {
        const boundary = cellToBoundary(e);

        const newViewState = {
            latitude: boundary[0][0],
            longitude: boundary[0][1],
            zoom: 10,
        };
        setViewState(newViewState);
        setZoom(10);
    };

    return <><Card className={'mb-3'}>
        <div className="map-container">
            {isMapDataLoading && (
                <div className="spinner-overlay">
                    <Spinner/>
                </div>
            )}
            <MapGL
                {...viewState}
                mapboxAccessToken={accessToken}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                onMove={handleVieStateChange}
                onClick={handleMapClick}

            >
                <NavigationControl showCompass={false}/>

                {occasionsCoords &&
                    occasionsCoords.map((coordsArray, index) => (
                        <>
                            <Source id={`line-${index}`} type="geojson" data={getGeoJson(coordsArray)}>
                                <Layer
                                    type="fill"
                                    paint={{
                                        'fill-color': '#0080ff',
                                        'fill-opacity': 0.25,
                                        'fill-outline-color': '#000',
                                        'fill-antialias': true
                                    }}
                                />
                            </Source>
                            {(coordsArray.length > 0 && zoom < 7.5) && (
                                <Marker
                                    key={`marker-${index}`}
                                    latitude={coordsArray[0][1]}
                                    longitude={coordsArray[0][0]}
                                >
                                    <div
                                        className="custom-marker ml-0"
                                        style={{marginTop: '-25px'}}
                                    >
                                        <img src='/images/map-pin2.png' alt='possible-match' width={'30px'}/>
                                    </div>
                                </Marker>
                            )}
                        </>
                    ))}

                {h3Boundary &&
                    <>
                        <Source type="geojson" data={getGeoJson(h3Boundary)}>
                            <Layer
                                type="fill"
                                paint={{
                                    'fill-color': '#0080ff',
                                    'fill-opacity': 0.65,
                                    'fill-outline-color': '#000',
                                    'fill-antialias': true
                                }}
                            />
                        </Source>
                        < Source type="geojson" data={getLineGeoJson(h3Boundary)}>
                            <Layer
                                type="line"
                                paint={{
                                    'line-color': '#5E00B5',
                                    'line-width': 2,
                                }}
                            />
                        </Source>
                    </>
                }
            </MapGL></div>
    </Card>
        {isMapDataLoading &&
            <Card className={'mb-3'}>
                <Card.Body>
                    Please be patient - locations list is loading, and it can take a couple of minutes to fetch
                </Card.Body>
            </Card>
        }
        {
            linkedH3ListLinked &&
            <Card className='mb-3'>
                <Card.Header>Linked</Card.Header>
                <Card.Body>
                    {Object.keys(linkedH3ListLinked).map((key, index) => {
                        return (
                            <div key={index}>
                                <p className="mb-0">{index+1}. Occasion: {key}</p>
                                {linkedH3ListLinked[key].map((el, subIndex) => {
                                    return (
                                        <Button variant="link" className='m-1 og-h3-list-button-link' onClick={() => setNewH3(el)} key={subIndex}>
                                            {el}
                                        </Button>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Card.Body>
            </Card>
        }
        {
            linkedH3ListSimilar &&
            <Card className='mb-3'>
                <Card.Header>Similar</Card.Header>
                <Card.Body>
                    {Object.keys(linkedH3ListSimilar).map((key, index) => {
                        return (
                            <div key={index}>
                                <p className="mb-0">{index+1}. Occasion: {key}</p> {/* Выводим название ключа */}
                                {linkedH3ListSimilar[key].map((el, subIndex) => {  // Проходимся по массиву, который лежит внутри ключа
                                    return (
                                        <Button variant="link" className='m-1 og-h3-list-button-link' onClick={() => setNewH3(el)} key={subIndex}>
                                            {el}
                                        </Button>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Card.Body>
            </Card>
        }
        {
            linkedH3ListDifferent &&
            <Card className='mb-3'>
                <Card.Header>Different</Card.Header>
                <Card.Body>
                    {Object.keys(linkedH3ListDifferent).map((key, index) => {
                        return (
                            <div key={index}>
                                <p className="mb-0">{index+1}. Occasion: {key}</p>
                                {linkedH3ListDifferent[key].map((el, subIndex) => {
                                    return (
                                        <Button variant="link" className='m-1 og-h3-list-button-link' onClick={() => setNewH3(el)} key={subIndex}>
                                            {el}
                                        </Button>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Card.Body>
            </Card>
        }

    </>
}

export default Mapbox;


function getGeoJson(coordsArray: any) {
    return {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: coordsArray,
        },
        properties: {},
    };
}

function getLineGeoJson(coordsArray: any) {
    return {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: coordsArray,
        },
        properties: {},
    };
}
