import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { removeToast} from "../Store/toasts"; // Путь к вашим действиям уведомлений

interface ToastsState {
    toasts : {
        notifications: Array<Notification>
    }
}

interface Notification {
    id: any,
    message: string,
}

const GlobalToastContainer: React.FC = () => {
    const notifications = useSelector((state: ToastsState) => state.toasts.notifications);
    const dispatch = useDispatch();

    const notify = (message: string) => toast(message, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
    });

    useEffect(() => {
        notifications.forEach((notification: Notification) => {
                notify(notification.message);
                const timer = setTimeout(() => {
                    dispatch(removeToast(notification.id));
                }, 3000);
                return () => clearTimeout(timer);
        });
    }, [notifications, dispatch]);

    return (
        <div>
            <ToastContainer limit={3} />
        </div>
    );
};

export default GlobalToastContainer;
