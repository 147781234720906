import React from "react";
import { GoogleLogin } from '@react-oauth/google';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {showToast} from "../Store/toasts";
import {logout, saveLoggedInStatus} from "../Store/user";

const LoginForm : React.FC = () => {
    const JWT_CHECK_URL : string | undefined = process.env.REACT_APP_OG_API;
    const dispatch = useDispatch();
    const [data, setData] = useState<string | null>(null);

    const handleGoogleLoginSuccess = (response: any) => {
        setData(response?.credential);
    };

    useEffect(() => {
        if (data === null || JWT_CHECK_URL === undefined) return;
        const decodedToken : any = jwt_decode(data);
        dispatch(saveLoggedInStatus(data));
        dispatch(showToast(`Hello, ${decodedToken.given_name.trim()}!`));

        // const fetchData = async () => {
        //     try {
        //         const response = await axios.get(JWT_CHECK_URL, {
        //             headers: {
        //                 'accept': 'application/json, text/plain, */*',
        //                 'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8,ru;q=0.7',
        //                 'authorization': 'Bearer ' + data,
        //                 'cache-control': 'no-cache',
        //                 'pragma': 'no-cache',
        //             }
        //         });
        //         if (response.data) {
        //             const decodedToken : any = jwt_decode(data);
        //             if (decodedToken.given_name) {
        //                 dispatch(showToast(`Hello, ${decodedToken.given_name.trim()}!`));
        //             } else {
        //                 dispatch(showToast('Login successful'));
        //             }
        //             dispatch(saveLoggedInStatus(data));
        //         }
        //     } catch (error) {
        //         // @ts-ignore
        //         const errorText = error?.response?.data;
        //         dispatch(showToast(errorText));
        //         dispatch(logout());
        //     }
        // };
        // fetchData();
    }, [data]);

    const handleGoogleLoginFailure = () => {
        console.error('error login!');
    };

    return (
        <div className="login-page__wrapper">
            <div className="login-page__container">
                <GoogleLogin onSuccess={handleGoogleLoginSuccess} onError={handleGoogleLoginFailure} />
            </div>
            <div className="login-page__video-container">
                <div className="login-page__logo-container">
                    <img style={{ width: "264px" }} src="/images/logo.svg" />
                </div>
            </div>
        </div>
    );
}

export default LoginForm;