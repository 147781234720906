import jwt_decode from "jwt-decode";
import {User} from "../Types/user";

const getInitialUserData = () : User | null => {
    const jwt = localStorage.getItem('userCredentials');
    if(jwt){
        return jwt_decode(jwt);
    }
    return null;
}
enum ActionTypes {
    SAVE_LOGGED_IN_STATUS = 'SAVE_LOGGED_IN_STATUS',
    LOGOUT = 'LOGOUT',
}
export interface UserState {
    isLoggedIn: boolean;
    userCredentials: string | null;
    user: User | null;
}

const initialState: UserState = {
    isLoggedIn: !!localStorage.getItem('userCredentials'),
    userCredentials: localStorage.getItem('userCredentials') || null,
    user: getInitialUserData(),
}

type Action = {
    type: ActionTypes;
    payload: any;
};

const userReducer = (state: UserState = initialState, action:Action): UserState => {
    switch (action.type) {
        case ActionTypes.LOGOUT:
            localStorage.removeItem('userCredentials');
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case ActionTypes.SAVE_LOGGED_IN_STATUS:
            const credentials = action.payload;

            localStorage.setItem('userCredentials', credentials);
            const decodedUser : User = jwt_decode<User>(credentials);
            return {
                ...state,
                isLoggedIn: true,
                userCredentials: action.payload,
                user: decodedUser
            };
        default:
            return state;
    }
}

export default userReducer;

export const saveLoggedInStatus = (credentials : string) => ({
    type: ActionTypes.SAVE_LOGGED_IN_STATUS,
    payload: credentials
})

export const logout = () => ({
    type: ActionTypes.LOGOUT
})

