import React, {useEffect, useState} from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import MainPage from "./Content/MainPage";
import Header from "./Controls/Header";
import GlobalLoader from "./GlobalLoader";
import SectionExo from "./Content/SectionExo";
import SectionH3 from "./Content/SectionH3";
import GlobalToastContainer from "./ToastContainer";
import axios from "axios";
import {showToast} from "../Store/toasts";
import {logout} from "../Store/user";
import LoginForm from "./LoginForm";

interface UserState {
    user: {
        isLoggedIn: boolean;
        userCredentials: string | null;
    }
}

const AppRouter: React.FC = () => {
    // const dispatch = useDispatch();
    // const loginCredentials = useSelector((state: UserState) => state.user.userCredentials);
    const isLoggedInRedux = useSelector((state: UserState) => state.user.isLoggedIn);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const JWT_CHECK_URL : string | undefined = process.env.REACT_APP_OG_API;

    useEffect(() => {
        setIsLoggedIn(isLoggedInRedux);
    }, [isLoggedInRedux])

    // useEffect(() => {
    //     if (loginCredentials === null || JWT_CHECK_URL === undefined) {
    //         setIsLoggedIn(false);
    //         return
    //     }
        // const fetchData = async () => {
        //     try {
        //         const response = await axios.get(JWT_CHECK_URL, {
        //             headers: {
        //                 'accept': 'application/json, text/plain, */*',
        //                 'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8,ru;q=0.7',
        //                 'authorization': 'Bearer ' + loginCredentials,
        //                 'cache-control': 'no-cache',
        //                 'pragma': 'no-cache',
        //             }
        //         });
        //         if (response.data) {
        //             setIsLoggedIn(true);
        //         }
        //     } catch (error) {
        //         //TODO deal with ts-ignore
        //         // @ts-ignore
        //         const errorText = error?.response?.data;
        //         dispatch(showToast(errorText));
        //         dispatch(logout());
        //     }
        // };
        // fetchData();
    // }, [loginCredentials])

    return <>
        <GlobalToastContainer/>
        <BrowserRouter>
            {!isLoggedIn && <LoginForm/>}
            {isLoggedIn && (<>
                    <GlobalLoader/>
                    <Menu/>
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/section/exo/:sectionId?" element={<SectionExo/>}/>
                        <Route path="/section/h3/:sectionId?" element={<SectionH3/>}/>
                    </Routes></>
            )}
        </BrowserRouter></>
};

function Menu() {
    return <Header/>
}

export default AppRouter