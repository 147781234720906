import {Notification} from "../Types/toasts";

enum ActionTypes {
    SHOW_TOAST = 'SHOW_TOAST',
    REMOVE_TOAST = 'REMOVE_TOAST',
}

export const showToast = (message: string) => ({
    type: ActionTypes.SHOW_TOAST,
    payload: message,
});

interface State {
    notifications: Array<Notification>;
}


export const removeToast = (id: any) => ({
    type: ActionTypes.REMOVE_TOAST,
    payload: id,
});

const initialState: State = {
    notifications: [],
};

type Action = {
    type: ActionTypes;
    payload: any;
};

const toastReducer = (state: State = initialState, action:Action): State => {
    switch (action.type) {
        case ActionTypes.SHOW_TOAST:
            return <State>{
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        id: Date.now(),
                        message: action.payload,
                    },
                ],
            };
        case ActionTypes.REMOVE_TOAST:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.id !== action.payload
                ),
            };
        default:
            return state;
    }
}

export default toastReducer;

