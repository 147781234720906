import React, {useEffect, useState} from "react";
import {Card, ListGroup, Tab, Tabs, Nav} from "react-bootstrap";
import {NavLink, useLocation} from "react-router-dom";
import Mapbox from "./Mapbox";
import {LocationState} from "../../Store/locations";
import {useSelector} from "react-redux";
import ExoLocationsSettings from "./ExoLocationsSettings";
import {SectionsState} from "../../Store/sections";

const WebsiteControls: React.FC = () => {

    const [isSectionsRoute, setIsSectionsRoute] = useState(false);
    // const [isAdminRoute, setIsAdminRoute] = useState(false);

    const locationState = useSelector((state: { locations: LocationState }) => state.locations);
    const sectionsState = useSelector((state: {sections : SectionsState}) => state.sections)
    const h3 = locationState.h3;
    const lat = locationState.lat;
    const lng = locationState.lng;
    const sectionsList = sectionsState.sections;

    const location = useLocation();

    useEffect(() => {
        setIsSectionsRoute(location.pathname.startsWith('/section/h3'));
        // setIsAdminRoute(location.pathname.startsWith('/admin'));
    }, [location]);


    return <Tabs
        defaultActiveKey='Map'
        className="mb-3"
    >
        <Tab eventKey="Map" title="Map">
            {
                isSectionsRoute ?
                    <><Mapbox/>
                        <Card className='mb-3'>
                            <Card.Header>Selected location</Card.Header>
                            <ListGroup variant="flush" className='text-font-weight-400 '>
                                <ListGroup.Item>H3: {h3 && h3}</ListGroup.Item>
                                <ListGroup.Item>Latitude: {lat && lat}</ListGroup.Item>
                                <ListGroup.Item>Longitude: {lng && lng}</ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </>
                    :
                    <Card>
                        <Card.Body>
                            Location control is available on H3 section page. Choose one on the main page.
                        </Card.Body>
                    </Card>
            }
        </Tab>

        {/* <Tab eventKey="h3" title="H3">
                            <Accordion className='mb-3'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Select location on MapBox</Accordion.Header>
                                    <Accordion.Body>
                                        <MapBox />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Find location by address</Accordion.Header>
                                    <Accordion.Body>
                                        <AddressAutocomplite />
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>

                            <Card className='mb-3'>
                                <Card.Header>Selected location</Card.Header>
                                <ListGroup variant="flush" className='text-font-weight-400 '>
                                    <ListGroup.Item>H3: {h3 && h3}</ListGroup.Item>
                                    <ListGroup.Item>Latitude: {lat && lat}</ListGroup.Item>
                                    <ListGroup.Item>Longitude: {long && long}</ListGroup.Item>
                                </ListGroup>
                            </Card>

                            <Card className='mb-3'>
                                <Card.Header>Date & Time</Card.Header>
                                <DateTimePicker />
                            </Card>

                        </Tab> */}
        {/* TODO temporary hidden */}
        {/*<Tab eventKey="exo" title="Exo Locations">*/}
        {/*    <ExoLocationsSettings/>*/}
        {/*    {exoLocationRedux &&*/}
        {/*        <Accordion className='mb-3' alwaysOpen>*/}
        {/*            {exoLocationRedux.map((exoLoc) => (*/}
        {/*                <Accordion.Item key={exoLoc.id} eventKey={exoLoc.id}>*/}
        {/*                    <Accordion.Header>Point {exoLoc.title}</Accordion.Header>*/}
        {/*                    <Accordion.Body>*/}
        {/*                        <LocationInput*/}
        {/*                            location={exoLoc}*/}
        {/*                        />*/}
        {/*                    </Accordion.Body>*/}
        {/*                </Accordion.Item>*/}

        {/*            ))}*/}
        {/*        </Accordion>*/}
        {/*    }*/}
        {/*    {exoLocationInfoMessage && <>*/}
        {/*        <div className='mt-3' />*/}
        {/*        Distance to Exo Locations:*/}
        {/*        <div>{exoLocationInfoMessage &&*/}
        {/*            <Card className='margin-top-14'>*/}
        {/*                <Card.Body>{exoLocationInfoMessage}</Card.Body>*/}
        {/*            </Card>*/}
        {/*        }</div>*/}
        {/*    </>*/}
        {/*    }*/}

        {/*    <>*/}
        {/*        <div className='mt-3'/>*/}
        {/*        {curPosition && <>*/}
        {/*            Current Position:*/}
        {/*            <Card className='margin-top-14'>*/}
        {/*                <Card.Body>{curPosition.latitude}</Card.Body>*/}
        {/*            </Card>*/}
        {/*            <Card className='margin-top-14'>*/}
        {/*                <Card.Body>{curPosition.longitude}</Card.Body>*/}
        {/*            </Card></>*/}
        {/*        }*/}
        {/*        <Card className='margin-top-14'>*/}
        {/*            <Card.Header>Demo sections for Exo Locations</Card.Header>*/}
        {/*            <ListGroup variant="flush" style={{ fontWeight: '400' }}>*/}
        {/*                {sectionsList && sectionsList.map((section, index) => (*/}
        {/*                    <ListGroup.Item key={index}>*/}
        {/*                        <NavLink className='nav-link' to={`section/exo/${section.section_id}`}>{section.title}</NavLink>*/}
        {/*                    </ListGroup.Item>*/}
        {/*                ))}*/}
        {/*            </ListGroup>*/}
        {/*        </Card>*/}
        {/*    </>*/}

        {/*</Tab>*/}
        {/*<Tab eventKey="admin" title="Links">*/}
        {/*    <Nav.Link href="https://steppechange.ker.su/admin" target={'blank'}>API admin dashboard</Nav.Link>*/}
        {/*    /!*<NavLink className='nav-link' to={`admin`}>Local admin dashboard</NavLink>*!/*/}

        {/*</Tab>*/}
    </Tabs>
}


export default WebsiteControls;