import { combineReducers } from 'redux';
import userReducer from "./user";
import sectionsReducer from "./sections";
import loadersReducer from "./loaders";
import toastReducer from "./toasts";
import locationsReducer from "./locations";

const rootReducer = combineReducers({
    user : userReducer,
    sections: sectionsReducer,
    loaders: loadersReducer,
    toasts: toastReducer,
    locations: locationsReducer
});

export default rootReducer;