import {ExoLocation, OccasionsBySectionType, Position} from "../Types/locations";

enum ActionTypes {
    SAVE_H3 = 'SAVE_H3',
    SAVE_LAT = 'SAVE_LAT',
    SAVE_LNG = 'SAVE_LNG',
    SAVE_DATETTIME = 'SAVE_DATETTIME',
    SAVE_CURRENT_POSITION = 'SAVE_CURRENT_POSITION',
    SAVE_EXO_LOCATIONS = 'SAVE_EXO_LOCATIONS',
    SAVE_OCCASIONS_BY_SECTION = 'SAVE_OCCASIONS_BY_SECTION',
    TOGGLE_MAP_LOADING_ON = 'TOGGLE_MAP_LOADING_ON',
    TOGGLE_MAP_LOADING_OFF = 'TOGGLE_MAP_LOADING_OFF'
}


export interface LocationState {
    h3: string | undefined;
    lat: number | undefined;
    lng: number | undefined;
    datetime: string | null;
    curposition: Position | null;
    exoLocations: {[key: string]: ExoLocation[]}  | null;
    occasionsBySection: OccasionsBySectionType | null;
    isMapDataLoading: boolean
}

const initialState: LocationState = {
    h3: localStorage.getItem('h3') || '872830c65ffffff',
    lat: localStorage.getItem('lat') ? parseFloat(localStorage.getItem('lat')!) : 37.90142204,
    lng: localStorage.getItem('long') ? parseFloat(localStorage.getItem('long')!) : -122.06207228,
    datetime: localStorage.getItem('datetime') || (new Date()).toISOString(),
    curposition: null,
    exoLocations: null,
    occasionsBySection : null,
    isMapDataLoading: false
};


type Action = {
    type: ActionTypes;
    payload: any;
};

const locationsReducer = (state: LocationState = initialState, action:Action): LocationState => {
    switch (action.type) {
        case ActionTypes.SAVE_H3:
            localStorage.setItem('h3', action.payload);
            return {
                ...state,
                h3: action.payload,
            };
        case ActionTypes.SAVE_LAT:
            localStorage.setItem('lat', action.payload);
            return {
                ...state,
                lat: action.payload,
            };
        case ActionTypes.SAVE_LNG:
            localStorage.setItem('long', action.payload);
            return {
                ...state,
                lng: action.payload,
            };
        case ActionTypes.SAVE_DATETTIME:
            localStorage.setItem('datetime', action.payload);
            return {
                ...state,
                datetime: action.payload,
            };
        case ActionTypes.SAVE_CURRENT_POSITION:
            return {
                ...state,
                curposition: action.payload,
            };
        case ActionTypes.SAVE_EXO_LOCATIONS:
            return {
                ...state,
                exoLocations: action.payload,
            };
        case ActionTypes.SAVE_OCCASIONS_BY_SECTION:
            return {
                ...state,
                occasionsBySection: action.payload,
            };
        case ActionTypes.TOGGLE_MAP_LOADING_ON:
            return {
                ...state,
                isMapDataLoading: true,
            };
        case ActionTypes.TOGGLE_MAP_LOADING_OFF:
            return {
                ...state,
                isMapDataLoading: false
            }
        default:
            return state;
    }
}

export default locationsReducer;


export const saveH3 = (h3 : string) => ({
    type: ActionTypes.SAVE_H3,
    payload: h3,
});

export const saveLat = (lat :  number | undefined) => ({
    type: ActionTypes.SAVE_LAT,
    payload: lat,
});

export const saveLng = (lng :  number | undefined) => ({
    type: ActionTypes.SAVE_LNG,
    payload: lng,
});

export const saveDateTime = (datetime : string) => ({
    type: ActionTypes.SAVE_DATETTIME,
    payload: datetime,
});

export const saveCurrentPosition = (currentPosition : Position) => ({
    type: ActionTypes.SAVE_CURRENT_POSITION,
    payload: currentPosition,
});

export const saveExoLocations = (exoLocations : Array<ExoLocation>) => ({
    type: ActionTypes.SAVE_EXO_LOCATIONS,
    payload: exoLocations,
});

export const saveOccasionsBySection = (occasionsBySection: OccasionsBySectionType | null) => ({
    type: ActionTypes.SAVE_OCCASIONS_BY_SECTION,
    payload: occasionsBySection,
});

export const toggleMapLoadingOff = () => ({type: ActionTypes.TOGGLE_MAP_LOADING_OFF});

export const toggleMapLoadingOn = () => ({type: ActionTypes.TOGGLE_MAP_LOADING_ON});


