import React, {useState} from "react";
import {Container, Navbar, Offcanvas} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import WebsiteControls from "./WebsiteControls";
import {logout, UserState} from "../../Store/user";
import {useDispatch, useSelector} from "react-redux";
import {LocationState} from "../../Store/locations";
import { useLocation } from 'react-router-dom';
const Header: React.FC = () => {
    const userState = useSelector((state: { user: UserState }) => state.user);
    const user = userState.user
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow((s) => !s)
    const handleClose = () => setShow(false);
    const dispatch = useDispatch();
    const handleLogout = () => dispatch(logout());
    const location = useLocation();
    const isMainPage = location.pathname === '/';

    return <>
        <header>
            <Navbar collapseOnSelect expand="lg" variant="light" className={'menu-header'}>
                <NavLink to="">
                    <div className={'menu-header__logo'}></div>
                </NavLink>
                {!isMainPage && (
                    <nav className={'menu-header__navigation'}>
                        <NavLink to="#" className={`p-2 pt-1 pb-1 m-1 menu-item ${show ? 'active-item' : ''}`}
                                 onClick={toggleShow}>
                            Website Settings
                        </NavLink>
                    </nav>
                )}
                <div className={'menu-header__profile'}>
                    <div className={'p-avatar p-avatar-circle p-avatar-image '}>
                        <img src={user?.picture ? user?.picture : '/images/avatar.png'} />
                    </div>
                    <NavLink to="#" className={`p-2 pt-1 pb-1 m-1 menu-item`} onClick={handleLogout}>
                        Logout
                    </NavLink>
                </div>
            </Navbar>
        </header>
        <Offcanvas show={show} onHide={handleClose} scroll={false} backdrop={true} placement={'end'}
                   className="w-50 minimum-width-450">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Website controls</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <WebsiteControls/>
            </Offcanvas.Body>
        </Offcanvas>
    </>
}

export default Header;