import React from "react";
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";

const GlobalLoader: React.FC = () => {
    const globalLoader = useSelector((state: { loaders: { globalLoader: boolean } }) => state.loaders.globalLoader);

    return globalLoader ? (<div className="fullscreen-spinner-overlay">
        <Spinner animation="border" role="status">
        </Spinner>
    </div>) : null
}

export default GlobalLoader;