import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {NewsItem, Section} from "../../Types/section";
import {ExoLocation, OccasionsBySectionType, Position} from "../../Types/locations";
import {useDispatch, useSelector} from "react-redux";
import {saveNewsBySection} from "../../Store/sections";
import {Card, Col, Container, Modal, Row} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {saveCurrentPosition, saveExoLocations} from "../../Store/locations";
import axios from "axios";
import {showToast} from "../../Store/toasts";
import * as geolib from 'geolib';
import ReactPlayer from "react-player";

const SectionExo: React.FC = () => {
    const {sectionId} = useParams();
    const SECTION_NEWS_API_URL = process.env.REACT_APP_API_LAYER_URL + 'news/';
    const EXO_LOCATIONS_API_URL = process.env.REACT_APP_API_LAYER_URL + 'event-locations/';
    const OCCASIONS_PULL_API_URL = process.env.REACT_APP_API_LAYER_URL + 'occasion-pull/';

    const [loading, setLoading] = useState(true);
    const [occasions, setOccasion] = useState<any[]|null>(null);
    const [sectionTitle, setSectionTitle] = useState(null);
    const [sectionNews, setSectionNews] = useState<NewsItem[] | null>(null);
    const [sectionEvents, setSectionEvents] = useState<NewsItem[] | null>(null);
    const [sectionPopularNews, setSectionPopularNews] = useState<NewsItem[] | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState<any | null>('');

    const dispatch = useDispatch();
    const exoLocations = useSelector((state: State) => state.locations.exoLocations);
    const curposition = useSelector((state: State) => state.locations.curposition);
    const sectionsList = useSelector((state: State) => state.sections.sections);
    const newsBySection = useSelector((state: State) => state.sections.newsBySection);
    const [closestPointText, setClosestPointText] = useState<string|null>(null);
    const [closestPointOfInterest, setClosestPoint] = useState<string|null>(null);


    useEffect(() => {
        if (!sectionsList) return;
        const curSection = sectionsList.find(section => section.section_id === sectionId);
        setSectionTitle(curSection?.title);
    }, [sectionsList, sectionId]);

    const handlePositionChange = (pos: any) => {
        const {latitude, longitude} = pos;
        dispatch(saveCurrentPosition({lat : latitude, lng : longitude}));
    };

    useEffect(() => {

        if (exoLocations === null) {
            axios.get(EXO_LOCATIONS_API_URL)
                .then(response => {
                    if (response.data[0]) {
                        const apiExoLocatons = response.data[0];
                        if (apiExoLocatons) {
                            dispatch(saveExoLocations(apiExoLocatons));
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                maximumAge: 1000,
            };

            const watchId = navigator.geolocation.watchPosition(
                function (position) {
                    handlePositionChange(position.coords);
                },

                function (error) {
                    if (error.code === error.PERMISSION_DENIED) {
                        const defaultPosition = {
                            latitude: 37.90142204,
                            longitude: -122.06207228
                        };
                        dispatch(showToast(`Please enable geolocation for demo purposes. Until then default coordinates are used: [${defaultPosition.latitude}, ${defaultPosition.longitude}]`));
                        handlePositionChange(defaultPosition);
                    }
                },
                options
            );

            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        } else {
            const defaultPosition = {
                latitude: 37.90142204,
                longitude: -122.06207228
            };

            dispatch(showToast(`Please enable geolocation for demo purposes. Until then default coordinates are used: [${defaultPosition.latitude}, ${defaultPosition.longitude}]`));
            handlePositionChange(defaultPosition);
        };

    }, []);

    useEffect(() => {
        if (!sectionId) return;

        if (newsBySection === null || (newsBySection && newsBySection[sectionId] === undefined)) {
            fetch(`${SECTION_NEWS_API_URL}${sectionId}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch");
                    }
                    return response.json();
                })
                .then((data) => {
                    const updatedNewsBySection = {
                        ...newsBySection,
                        [sectionId]: data,
                    };
                    dispatch(saveNewsBySection(updatedNewsBySection));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [sectionId, SECTION_NEWS_API_URL, dispatch, newsBySection])

    useEffect(() => {
        if (!sectionId) return;
        if (!(newsBySection) || (sectionId && newsBySection[sectionId] === undefined)) return;
        setSectionNews(newsBySection[sectionId]['news']);
        setSectionEvents(newsBySection[sectionId]['events']);
        if (newsBySection[sectionId]['popular'].length >= 1) {
            setSectionPopularNews(newsBySection[sectionId]['popular']);
        } else {
            setSectionPopularNews(null);
        }
    }, [newsBySection, sectionId])

    useEffect(() => {
        if (exoLocations && curposition) {
            const { lat, lng } = curposition;
            let resultsArray : any[] = [];

            exoLocations.forEach(el => {
                let distanceEL = geolib.getDistance(
                    {
                        latitude: parseFloat(lat),
                        longitude: parseFloat(lng)
                    },
                    {
                        latitude: el.lat,
                        longitude: el.long
                    }
                );

                let resultObj = {
                    id: el.id,
                    key: el.key,
                    title: el.title,
                    distance: distanceEL
                };
                resultsArray.push(resultObj);
            });


            const closestPoint = resultsArray.reduce((minDistanceEl, el) => {
                return el.distance < minDistanceEl.distance ? el : minDistanceEl;
            });

            let message = `Closest point: ${closestPoint.title}.`;

            resultsArray.forEach(el => {
                message += ` Distance to ${el.title}: ${el.distance}m.`;
            });

            updateClosestPoint(closestPoint.key, message, closestPoint?.title);
        }
    }, [exoLocations, curposition]);

    function updateClosestPoint(point : string, message : string, title : string) {
        if (point !== closestPointOfInterest && point !== undefined) {
            setClosestPoint(point);
            dispatch(showToast(`Closest Point: ${title}. Updating occasions.`));
            setClosestPointText(message);
        }
        return point;
    }

    const getOccasions = () => {
        setLoading(true);
        let result;
        const queryParams = new URLSearchParams();

        queryParams.append('group', 'golf');

        if (closestPointOfInterest !== null) {
            queryParams.append('closest_point', closestPointOfInterest);
        }

        setTimeout(() => {
            fetch(`${OCCASIONS_PULL_API_URL}?${queryParams}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch");
                    }
                    return response.json();
                })
                .then((data) => {
                    result = data;
                    setOccasion(data);
                    setLoading(false);
                })
                .catch((error) => {
                    // Handle the error
                });
        }, 1000);

        return result;
    }

    useEffect(() => {
        if (closestPointOfInterest !== null) {
            getOccasions();
        }
    }, [closestPointOfInterest]);

    const handleCardClick = (bannerObj : any) => {
        setSelectedVideoUrl(bannerObj);
        setShowModal(true);
    }

    const handleCloseModal = () => setShowModal(false);


    return <Container fluid className={'content-category'}>
        <Row className={'content-category__row'}>
            <Col xs={12} sm={12} md={12} lg={6} className={'h-100 occasions'}>
                <Row className="h-100 d-flex flex-wrap justify-content-between">
                    <Col xs={12} sm={12} md={12} lg={12} className="h-100 my-2">
                        <Card className={'occasions__id'}>
                            {closestPointText && closestPointText}
                        </Card>
                    </Col>
                    {loading ?
                        <>
                            {Array(3)
                                .fill(3)
                                .map((item, index) => (
                                    <Col xs={12} sm={12} md={12} lg={12} className="h-100 my-2" key={index}>
                                        <Card className={'occasions__skeleton'}>
                                            <div className='occasion-top'></div>
                                            <Skeleton duration={0.75} height="100%" style={{lineHeight: '2'}}
                                                      containerClassName="skeleton-container"/>
                                        </Card>
                                    </Col>
                                ))}
                        </>
                            :
                        (occasions &&
                            occasions.map((occ, index) => (
                                <Col key={occ.id} xs={12} sm={12} md={12} lg={12} className="h-100 my-2">
                                    <Card
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '121px',
                                            margin: '0 10px',
                                            filter: 'grayscale(25%)',
                                            backgroundImage: `url(${occ.image_url}`,
                                            backgroundSize: 'cover',
                                        }}
                                        onClick={() => handleCardClick(occ)}

                                    >
                                        <p style={{ position: 'absolute', left: '16px', bottom: '16px', marginBottom: '0px', color: '#FFF' }}>Occasion : {occ.title}</p>
                                        <div className='occasion-top'>Top {index + 1}</div>

                                    </Card>
                                </Col>
                            )))
                    }
                </Row>
            </Col>
            <Col sm={12} md={12} lg={6} style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '32px',
                paddingTop: '24px',
                background: '#F8F9FB'
            }}>
                <Row>
                    <Col>
                        <div className='content-header'><p>{sectionTitle} Latest
                            News</p></div>
                    </Col>
                </Row>
                <Row className="h-100 d-flex flex-wrap justify-content-between">
                    {sectionNews && sectionNews.map((article) => (
                        <Col key={article.id} xs={12} sm={12} md={6} lg={6}
                             style={{
                                 borderTop: article.id === 1 || article.id === 2 ? '' : '1px solid rgba(139, 145, 154, 0.5)',
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                             }}
                        >
                            <div className='content-newscard__text'
                                 style={{minHeight: '100px', cursor: 'default', width: '100%'}}>
                                {article.title}
                                <div className='content-newscard__subheader'
                                     style={{
                                         color: '#8B919A',
                                         fontWeight: '400',
                                         fontSize: '14px',
                                         borderLeft: '1px solid #C1C7CD',
                                         height: '16px',
                                         paddingLeft: '8px',
                                         lineHeight: '16px',
                                         marginTop: '16px'
                                     }}>
                                    {new Date(article.created_at).getHours()} HOURS AGO
                                </div>
                            </div>
                        </Col>

                    ))}
                </Row>
            </Col>
        </Row>
        {sectionPopularNews ?
            <Row style={{marginBottom: '32px', padding: '32px 32px 24px'}}>
                {sectionPopularNews.map((popularArticle) => (
                    <Col key={popularArticle.id} xs={12} sm={12} md={12} lg={6} className='mb-4'
                         style={{paddingLeft: '0px'}}>
                        <Card style={{
                            height: "234px",
                            display: 'flex',
                            padding: '24px 32px',
                            backgroundImage: `url(${popularArticle.image})`,
                            backgroundSize: 'cover'
                        }}>

                            <div className='content-category__tag'>POPULAR NEWS</div>

                            <div className='content-category__title'>{popularArticle.title}</div>
                        </Card>
                    </Col>
                ))
                }
            </Row>
            :
            <Row style={{marginBottom: '32px', padding: '32px 32px 24px'}}>
                <Col xs={12} sm={12} md={12} lg={6} className='mb-4' style={{paddingLeft: '0px'}}>
                    <Card style={{height: "234px", display: 'flex', padding: '24px 32px'}}>
                        <div className='content-category__tag'>POPULAR NEWS</div>
                        <div className='content-category__title'>TBD</div>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className='mb-4' style={{padding: '0px'}}>
                    <Card style={{height: "234px", display: 'flex', padding: '24px 32px'}}>
                        <div className='content-category__tag'>POPULAR NEWS</div>
                        <div className='content-category__title'>TBD</div>

                    </Card>
                </Col>
            </Row>
        }
        <Row style={{marginBottom: '32px', padding: '0px 32px 24px'}}>
            {sectionEvents && sectionEvents.map((event) => (
                <Col
                    key={event.id}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="col flex justify-content-center"
                    style={{paddingLeft: '0px', paddingRight: '24px'}}
                >
                    <div className='content-newscard__text' style={{minHeight: '100px'}}>
                        {event.title}
                        <div className='content-newscard__subheader'
                             style={{
                                 color: '#8B919A',
                                 fontWeight: '400',
                                 fontSize: '14px',
                                 borderLeft: '1px solid #C1C7CD',
                                 height: '16px',
                                 paddingLeft: '8px',
                                 lineHeight: '16px',
                                 marginTop: '16px'
                             }}>
                            {event.event}
                        </div>

                    </div>
                </Col>
            ))}
        </Row>
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {selectedVideoUrl.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '500px' }}>
                <ReactPlayer
                    className="react-player"
                    url={selectedVideoUrl.url ? selectedVideoUrl.url : 'https://youtu.be/Tzfz81sowEE'}
                    width="100%"
                    height="100%"
                    controls={false}
                />
            </Modal.Body>
        </Modal>
    </Container>
}

export default SectionExo


interface NewsBySection {
    [section: string]: Section;
}

interface State {
    sections: {
        sections: Array<any> | null;
        newsBySection: NewsBySection | null;
        occasionsBySection: OccasionsBySectionType | null;
    },
    user: {
        userCredentials: string | null;
    },
    locations: {
        h3: string | null,
        curposition: Position | null;
        exoLocations: ExoLocation[] | null;
    }
}
