import React, {useEffect} from "react";
import {Container, Row, Col, Card} from 'react-bootstrap';
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {saveSections, saveNewsMainPage} from "../../Store/sections";
import {toggleGlobalLoader} from "../../Store/loaders";
import {Link} from "react-router-dom";

interface SectionsState {
    sections: {
        sections: Array<any> | null;
        newsMainPage: Array<any> | null;
    }
}

const MainPage: React.FC = () => {
    const NEWS_API_URL: string | undefined = process.env.REACT_APP_API_LAYER_URL + 'news';
    const SECTION_API_URL: string | undefined = process.env.REACT_APP_API_LAYER_URL + 'sections';
    const WEBSITE_TYPE: string | undefined = process.env.REACT_APP_WEBSITE_TYPE;
    let WEBSITE_TYPE_CAPITALIZED: string = "";

    if (WEBSITE_TYPE) {
        WEBSITE_TYPE_CAPITALIZED = WEBSITE_TYPE.charAt(0).toUpperCase() + WEBSITE_TYPE.slice(1);
    }
    const dispatch = useDispatch();
    const sectionsList = useSelector((state: SectionsState) => state.sections.sections);
    const newsMainPage = useSelector((state: SectionsState) => state.sections.newsMainPage);

    useEffect(() => {
        if (sectionsList === null) {
            dispatch(toggleGlobalLoader());
            axios.get(`${SECTION_API_URL}?group=${WEBSITE_TYPE}`)
                .then(response => {
                    const sections = response.data;
                    if (sections && sectionsList === null) {
                        dispatch(saveSections(sections));
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            setTimeout(() => {
                dispatch(toggleGlobalLoader());
            }, 1000);

        }
    }, [SECTION_API_URL, WEBSITE_TYPE, dispatch, sectionsList])

    useEffect(() => {
        if (newsMainPage !== null) {
            return;
        }
        axios.get(`${NEWS_API_URL}?group=${WEBSITE_TYPE}`)
            .then(response => {
                const news = response.data;
                if (news) {
                    dispatch(saveNewsMainPage(news));
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [NEWS_API_URL, WEBSITE_TYPE, dispatch, newsMainPage]);

    return (
        <Container fluid={true} className='content'>
            <Row>
                <Col>
                    <div className='content__header'><p>{WEBSITE_TYPE_CAPITALIZED}</p></div>
                </Col>
            </Row>
            <Row>
                {sectionsList && sectionsList.map((section) => (
                    <Col key={section.id} xs={12} sm={12} md={6} lg={3} className='mb-4 height-500'>
                        <Card className='flex'
                              style={{
                                  backgroundImage: `url(${section.image})`,
                                  backgroundPositionX: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  height: '500px',
                                  maxWidth: '600px'
                              }}
                        >
                            <div className='website-type'>{WEBSITE_TYPE && WEBSITE_TYPE.toUpperCase()}</div>
                            <Link to={`/section/h3/${section.section_id}`}>
                                <Card.Body className='content-card__body'>
                                    <Card.Title className='content-card__title'>{section.title}</Card.Title>
                                    <img
                                        src='/images/arrow.png'
                                        alt=''
                                        style={{marginLeft: '16px', cursor: 'pointer'}}
                                    />
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row className='news-block'>
                {newsMainPage && newsMainPage.map((article) => (
                    <Col key={article.id} xs={12} sm={12} md={6} lg={3} className='mb-4'>
                        <Card.Text className='news-block__text'>
                            {article.title}
                        </Card.Text>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src='/images/sport-type.png' alt='' style={{ marginRight: '0px', width: '15px', height: '17.65px' }} />
                            <div className='news-block__subheader-border' />
                            <div className='news-block__subheader'>
                                {article.section}
                            </div>
                        </div>
                    </Col>

                ))}
            </Row>
        </Container>
    );
}

export default MainPage;