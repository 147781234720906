export enum ActionTypes {
    SAVE_SECTIONS = 'SAVE_SECTION',
    SAVE_NEWS_MAIN_PAGE = 'SAVE_NEWS_MAIN_PAGE',
    SAVE_NEWS_BY_SECTION = 'SAVE_NEWS_BY_SECTION',
    SAVE_OCCASIONS_BY_SECTION = 'SAVE_OCCASIONS_BY_SECTION',
}
export interface SectionsState {
    sections: Array<any> | null;
    newsMainPage: Array<any> | null;
    newsBySection: {[key: string]: any[]} | null;
    occasionsBySection: Array<any> | null;
}

const initialState: SectionsState = {
    sections: null,
    newsMainPage: null,
    newsBySection: null,
    occasionsBySection: [],
}

type Action = {
    type: ActionTypes;
    payload: any;
};

const sectionsReducer = (state: SectionsState = initialState, action:Action): SectionsState => {
    switch (action.type) {
        case ActionTypes.SAVE_SECTIONS:
            return {
                ...state,
                sections: action.payload,
            };
        case ActionTypes.SAVE_NEWS_MAIN_PAGE:
            return {
                ...state,
                newsMainPage: action.payload,
            };
        case ActionTypes.SAVE_NEWS_BY_SECTION:
            return {
                ...state,
                newsBySection: action.payload,
            };
        case ActionTypes.SAVE_OCCASIONS_BY_SECTION:
            return {
                ...state,
                occasionsBySection: action.payload,
            };
        default:
            return state;
    }
}
export const saveSections = (sections: Array<any> | null) => {
    return {
        type: ActionTypes.SAVE_SECTIONS,
        payload: sections,
    };
};

export const saveNewsMainPage = (newsMainPage: Array<any> | null) => {
    return {
        type: ActionTypes.SAVE_NEWS_MAIN_PAGE,
        payload: newsMainPage,
    };
};


export const saveNewsBySection = (newsBySection: {[key: string]: any[]} | null) => {
    return {
        type: ActionTypes.SAVE_NEWS_BY_SECTION,
        payload: newsBySection,
    };
};
export default sectionsReducer;