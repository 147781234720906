export enum ActionTypes {
    TOGGLE_GLOBAL_LOADER = 'TOGGLE_GLOBAL_LOADER',
    TOGGLE_MAP_LOADER_ON = 'TOGGLE_MAP_LOADER_ON',
    TOGGLE_MAP_LOADER_OFF = 'TOGGLE_MAP_LOADER_OFF',
}

type Action = {
    type: ActionTypes;
    payload: any;
};
interface State {
    globalLoader: boolean,
    mapLoader: boolean,
}

const initialState: State = {
    globalLoader: false,
    mapLoader: false,
}

const loadersReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case ActionTypes.TOGGLE_GLOBAL_LOADER:
            return {
                ...state,
                globalLoader: !state.globalLoader,
            };
        case ActionTypes.TOGGLE_MAP_LOADER_ON:
            return {
                ...state,
                mapLoader: true,
            };
        case ActionTypes.TOGGLE_MAP_LOADER_OFF:
            return {
                ...state,
                mapLoader: false,
            };
        default:
            return state;
    }
}

export const toggleGlobalLoader = () => {
    return {
        type: ActionTypes.TOGGLE_GLOBAL_LOADER
    };
};
export default loadersReducer;

